import React from "react";
import { Fade } from "react-awesome-reveal";
import styles from "./home.module.scss";

const Home = () => {
  return (
    <div className={styles.Home}>
      <Fade style={{ width: "100%", height: "90%" }} direction="right">
        <div className={styles.centerBlock}>
          <div className={styles.leftText}>
            Automated Task Management and Github Monitoring that goes hand in
            hand with this notion template:{" "}
            <a
              className={styles.link}
              href="https://lily-ravioli-0c0.notion.site/Coding-Task-Manager-abe2e2a633e5467cb3738d8727b3b268"
            >
              https://lily-ravioli-0c0.notion.site/Coding-Task-Manager-abe2e2a633e5467cb3738d8727b3b268
            </a>
          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={
                "https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Landing_3.PNG?alt=media&token=f137dbbd-d682-447a-960e-bc3537e00580"
              }
            />
            <img
              className={styles.image}
              src={
                "https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Landing_2.PNG?alt=media&token=0581030b-27d7-410d-a0f5-f3fb38f33c3c"
              }
            />
            <img
              className={styles.image}
              src={
                "https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Landing_1.PNG?alt=media&token=0f346783-c8b7-4274-9fd6-84007a2f5855"
              }
            />
          </div>
        </div>
      </Fade>
      <div className={styles.footer}></div>
    </div>
  );
};
export default Home;
