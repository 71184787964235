import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import SignIn from "./Auth/SignIn/SignIn";
import SignUp from "./Auth/SignUp/SignUp";
import Setup from "./Setup/Setup";
import Header from "../common/components/Header/Header";
import styles from "./app.module.scss";
import Subscription from "./Subscription/Subscription";
import SubscriptionPurchase from "./SubscriptionPurchase/SubscriptionPurchase";
import { useDispatch } from "react-redux";
import { auth, listenToUserData, monitorAuth } from "../common/firebase/utils";
import Account from "./Account/Account";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsub = monitorAuth(() => {
      return listenToUserData(dispatch);
    });
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [auth.currentUser]);

  return (
    <BrowserRouter>
      <div className={styles.App}>
        <Header />
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/auth/signup"} element={<SignUp />} />
          <Route path={"/auth/signin"} element={<SignIn />} />
          <Route path={"/setup"} element={<Setup />} />
          <Route path={"/subscription"} element={<Subscription />} />
          <Route
            path="/subscription/purchase"
            element={<SubscriptionPurchase />}
          />
          <Route path="/account" element={<Account />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
