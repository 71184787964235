import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  auth,
  cancelSubscription,
  hasSubscription,
  logOut,
} from "../../common/firebase/utils";
import styles from "./account.module.scss";
import Modal from "react-modal";
import { ACTIONS } from "../../common/redux/actions";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [canProceed, setCanProceed] = useState(true);
  const [isCanceling, setIsCanceling] = useState(false);
  const [databaseIds, setDatabaseIds] = useState({
    task_database_id: "",
    commit_database_id: "",
    pr_database_id: "",
  });
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const asyncUseEffect = async () => {
    setCanProceed(
      (await hasSubscription(
        auth.currentUser.providerData[0].uid
      )) as unknown as boolean
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/auth/signup");
    } else {
      asyncUseEffect();
    }
  }, [canProceed]);

  useEffect(() => {
    if (!canProceed) {
      navigate("/subscription");
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress style={{ color: "teal" }} />
      </div>
    );
  }
  return (
    <div className={styles.Account}>
      <div className={styles.centerBlock}>
        <div className={styles.header}>
          Welcome, {auth.currentUser.displayName}
        </div>
        <div>
          <div className={styles.title}>Update Notion Database Ids:</div>
          <div className={classNames(styles.step, styles.inputStep)}>
            <label>Tasks Database Id:</label>
            <input
              onChange={(e) => {
                setDatabaseIds({
                  ...databaseIds,
                  task_database_id: e.target.value,
                });
              }}
              className={styles.inputs}
              placeholder="tasks database id"
            />
            <button
              disabled={!databaseIds.task_database_id}
              className={styles.submitBtn}
            >
              Update
            </button>
          </div>
          <div className={classNames(styles.step, styles.inputStep)}>
            <label>Commits Database Id:</label>
            <input
              onChange={(e) => {
                setDatabaseIds({
                  ...databaseIds,
                  commit_database_id: e.target.value,
                });
              }}
              className={styles.inputs}
              placeholder="commits database id"
            />
            <button
              disabled={!databaseIds.commit_database_id}
              className={styles.submitBtn}
            >
              Update
            </button>
          </div>
          <div className={classNames(styles.step, styles.inputStep)}>
            <label>PRs Database Id:</label>
            <input
              onChange={(e) => {
                setDatabaseIds({
                  ...databaseIds,
                  pr_database_id: e.target.value,
                });
              }}
              className={styles.inputs}
              placeholder="prs database id"
            />
            <button
              disabled={!databaseIds.pr_database_id}
              className={styles.submitBtn}
            >
              Update
            </button>
          </div>
        </div>
        <button
          onClick={() => {
            setCancelModalIsOpen(true);
          }}
          className={styles.cancelBtn}
        >
          Cancel Subscription
        </button>
        <button
          onClick={() => {
            logOut().then(() => {
              dispatch({ type: ACTIONS.LOG_OUT, payload: null });
              navigate("/");
            });
          }}
          className={styles.cancelBtn}
        >
          Log Out
        </button>
      </div>
      <Modal
        style={{
          content: {
            width: "30%",
            height: "30%",
            top: "35%",
            left: "35%",
            borderRadius: "10px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 40%)",
          },
        }}
        isOpen={cancelModalIsOpen}
        onRequestClose={() => {
          setCancelModalIsOpen(false);
        }}
      >
        <div className={styles.cancelContainer}>
          {isCanceling ? (
            <div className={styles.loadingContainer}>
              <CircularProgress style={{ color: "teal" }} />
            </div>
          ) : (
            <>
              {" "}
              <div className={styles.header}>
                Are you sure you want to cancel your subscription?
              </div>
              <div>
                If you cancel now you'll no longer have access to the
                functionality of the Github App immediately after canceling. We
                recommend you cancel before your next invoice (aka payment).
              </div>
              <div className={styles.btnsContainer}>
                <button
                  className={styles.yesBtn}
                  onClick={() => {
                    setIsCanceling(true);
                    cancelSubscription(
                      auth.currentUser.providerData[0].uid
                    ).then(() => {
                      navigate("/");
                    });
                  }}
                >
                  Yes
                </button>
                <button
                  className={styles.noBtn}
                  onClick={() => {
                    setCancelModalIsOpen(false);
                  }}
                >
                  No
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default Account;
