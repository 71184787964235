import React from "react";
import { useNavigate } from "react-router-dom";
import { signInWithGit } from "../../../common/firebase/utils";
import styles from "./signin.module.scss";
const SignIn = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.SignIn}>
      <div className={styles.middleBlob}>
        <div className={styles.title}>Sign In With Github</div>
        <img
          className={styles.image}
          src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
        />
        <button
          className={styles.button}
          onClick={() => {
            signInWithGit().then(() => {
              navigate("/account");
            });
          }}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};
export default SignIn;
