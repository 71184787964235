import CircularProgress from "@mui/material/CircularProgress";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  auth,
  createSetupIntent,
  submitSubscription,
} from "../../../common/firebase/utils";
import styles from "./subscriptionform.module.scss";

const SubscriptionForm = ({
  plan,
  onSubmit,
}: {
  plan: number;
  onSubmit: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [issues, setIssues] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [name, setName] = useState({ first: "", last: "" });

  const handleSubmit = async () => {
    setHasSubmitted(true);
    if (!stripe || !elements) {
      return;
    }
    if (!name.first || !name.last) {
      setIssues(["Please make sure to fill out all fields"]);
      setHasSubmitted(false);
      return;
    }
    if (plan === 0 || plan === 1) {
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
      });
      if (!paymentMethod.error) {
        await submitSubscription(
          auth.currentUser.providerData[0].uid,
          plan === 0 ? "monthly" : "yearly",
          paymentMethod.paymentMethod.id,
          name.first + " " + name.last
        );
        onSubmit();
      } else {
        setIssues([paymentMethod.error]);
      }
      setHasSubmitted(false);
    }
  };
  return (
    <div className={styles.SubscriptionForm}>
      {issues.map((issue) => (
        <div className={styles.issue}>{issue}</div>
      ))}
      <div className={styles.header}>Enter your payment details</div>
      <div className={styles.nameContainer}>
        <input
          className={styles.inputs}
          onChange={(e) => {
            setName({ ...name, first: e.target.value });
          }}
          placeholder="First Name"
        />
        <input
          onChange={(e) => {
            setName({ ...name, last: e.target.value });
          }}
          className={styles.inputs}
          placeholder="Last Name"
        />
      </div>
      <CardElement className={styles.inputs} />
      <div className={styles.header}>Purchase Summary</div>
      <div className={styles.purchaseSummary}>
        <div className={styles.line}>
          Plan:{" "}
          <span className={styles.cost}>
            {plan === 0 ? "$3/Month" : "$30/Year"}
          </span>
        </div>
        <div className={styles.line}>
          Total:{" "}
          <span className={styles.cost}>
            {plan === 0 ? "$3/Month" : "$30/Year"}
          </span>
        </div>
      </div>
      <div>
        By clicking the 'Submit Purchase' button below, you agree to our Terms
        of Use, and Privacy Policy. You agree that your membership will begin
        immediately, and that you will not be able to withdraw from the contract
        and recieve a refund. The {plan === 0 ? "monthly" : "yearly"} plan's
        membership fee will be charged immediately and will recur on a{" "}
        {plan === 0 ? "monthly" : "yearly"} basis until you cancel. NOTE: Should
        you choose to cancel the subscription in the future, you will
        immediately lose access to our Github App service after canceling your
        subscription.
      </div>
      <button
        className={styles.submitBtn}
        onClick={() => {
          handleSubmit();
        }}
      >
        {hasSubmitted ? (
          <CircularProgress style={{ color: "white" }} />
        ) : (
          "Submit Purchase"
        )}
      </button>
    </div>
  );
};
export default SubscriptionForm;
