import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { createSetupIntent } from "../../common/firebase/utils";
import { useQuery } from "../../common/utils/hooks";
import SubscriptionForm from "./SubscriptionForm/SubscriptionForm";
import styles from "./subscriptionpurchase.module.scss";

const stripePromise = loadStripe("pk_test_RrYzYgFVf3Eqqm4gJERzy3VL003mN4DWIl");

const SubscriptionPurchase = () => {
  const params = useQuery();
  const navigate = useNavigate();
  const { plan } = params;
  const onSubmit = () => {
    navigate("/setup");
  };
  if (plan !== "0" && plan !== "1") {
    navigate("/subscription");
  }
  return (
    <div className={styles.SubscriptionPurchase}>
      <div className={styles.blob}>
        <Elements stripe={stripePromise}>
          <SubscriptionForm
            onSubmit={() => {
              onSubmit();
            }}
            plan={parseInt(plan)}
          />
        </Elements>
      </div>
    </div>
  );
};
export default SubscriptionPurchase;
