import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./header.module.scss";
const Header = () => {
  const userData = useSelector((state) => (state as any).userData);
  return (
    <div className={styles.Header}>
      <Link to={"/"} className={styles.logo}>
        Notion-CTM
      </Link>
      {userData ? (
        <div className={styles.linksContainer}>
          <Link to={"/setup"} className={styles.link}>
            Setup
          </Link>
          <Link to={"/account"} className={styles.link}>
            Account
          </Link>
        </div>
      ) : (
        <div className={styles.linksContainer}>
          <Link to={"/auth/signin"} className={styles.link}>
            Sign In
          </Link>
          <Link to={"/auth/signup"} className={styles.link}>
            Sign Up
          </Link>
        </div>
      )}
    </div>
  );
};
export default Header;
