import { useMemo } from "react";

export const useQuery = () => {
  const { search } = window.location;

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  );
};
