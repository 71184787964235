import React, { useState } from "react";
import styles from "./subscription.module.scss";

const Subscription = () => {
  const [chosenPlan, setChosenPlan] = useState(0);
  return (
    <div className={styles.Subscription}>
      <div className={styles.title}>Choose your plan!</div>
      <div className={styles.subscriptionsContainer}>
        <div className={styles.plan}>
          <div className={styles.mainPriceInfo}>
            <div className={styles.generalSub}>$3/Month</div>
            <div className={styles.asIfSub}>Equivalent to $36/Year</div>
          </div>
          <div>Monthly Access</div>
          <div className={styles.capabilities}>
            Full access to all the capabilities of the Notion-CTM Github app
          </div>
          <a href="/subscription/purchase?plan=0">
            <button className={styles.getStartedBtn}>Get Started!</button>
          </a>
        </div>
        <div className={styles.plan}>
          <div className={styles.mainPriceInfo}>
            <div className={styles.generalSub}>$30/Year</div>
            <div className={styles.discountContainer}>
              <div className={styles.oldPrice}>$36/Year</div>
              <div className={styles.discount}>-16.67%</div>
            </div>
            <div className={styles.asIfSub}>Equivalent to $2.50/Month</div>
          </div>
          <div>Yearly Access</div>
          <div className={styles.capabilities}>
            Full access to all the capabilities of the Notion-CTM Github app
          </div>
          <a href="/subscription/purchase?plan=1">
            <button className={styles.getStartedBtn}>Get Started!</button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
