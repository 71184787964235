import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  hasSubscription,
  updateDatabaseIds,
} from "../../common/firebase/utils";
import { Fade } from "react-awesome-reveal";
import styles from "./setup.module.scss";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { useSelector } from "react-redux";

const Setup = () => {
  const [currPage, setCurrPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [databaseIds, setDatabaseIds] = useState({
    task_database_id: "",
    commit_database_id: "",
    pr_database_id: "",
  });
  const [canProceed, setCanProceed] = useState(true);
  const pages = useMemo(
    () => [
      <>
        <div className={classNames(styles.step, styles.header)}>
          Follow this guide to integrate your new app!
        </div>
        <div className={styles.step}>
          1. First off make sure you've integrated the template made for this
          app into your notion:{" "}
          <a href="https://lily-ravioli-0c0.notion.site/Coding-Task-Manager-abe2e2a633e5467cb3738d8727b3b268">
            https://lily-ravioli-0c0.notion.site/Coding-Task-Manager-abe2e2a633e5467cb3738d8727b3b268
          </a>
        </div>
        <div className={styles.step}>
          2. Now click the arrow of the Coding Task Manager page to open the
          rest of the pages below it.
        </div>
        <img
          style={{ width: "20%" }}
          src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_1.PNG?alt=media&token=25d66943-d3c3-4878-8afb-0af9c09f77f2"
        />
        <div className={styles.step}>
          3. Go to the tasks page and click on the "Share" button at the top
        </div>
        <img
          style={{ width: "100%" }}
          src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_2.PNG?alt=media&token=cb3355ad-3e8d-4647-a59d-4f55c01c830a"
        />
        <div className={styles.step}>
          4. Copy the link and paste it in your browser, then copy the id of the
          database, which is between "/" and "?v="
        </div>
        <img
          style={{ width: "80%" }}
          src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_3.PNG?alt=media&token=75c31e72-2a6e-4f6f-9cbb-8a048cad2f9e"
        />
        <div className={styles.step}>Now paste it into the input below</div>
        <div className={classNames(styles.step, styles.inputStep)}>
          <label>Tasks Database Id:</label>
          <input
            onChange={(e) => {
              setDatabaseIds((databaseIds) => ({
                ...databaseIds,
                task_database_id: e.target.value,
              }));
            }}
            placeholder="tasks database id"
            className={styles.inputs}
          />
        </div>
        <div className={styles.step}>
          Repeat this for the Commits page and PRs page, then paste them in
          their respective inputs below
        </div>
        <div className={classNames(styles.step, styles.inputStep)}>
          <label>Commits Database Id:</label>
          <input
            onChange={(e) => {
              setDatabaseIds((databaseIds) => ({
                ...databaseIds,
                commit_database_id: e.target.value,
              }));
            }}
            className={styles.inputs}
            placeholder="commits database id"
          />
        </div>
        <div className={classNames(styles.step, styles.inputStep)}>
          <label>PRs Database Id:</label>
          <input
            onChange={(e) => {
              setDatabaseIds((databaseIds) => ({
                ...databaseIds,
                pr_database_id: e.target.value,
              }));
            }}
            className={styles.inputs}
            placeholder="pr database id"
          />
        </div>
        <button
          className={styles.finishedBtn}
          onClick={async () => {
            setIsLoading(true);
            if (
              databaseIds.task_database_id &&
              databaseIds.pr_database_id &&
              databaseIds.commit_database_id
            ) {
              await updateDatabaseIds(databaseIds);
              setCurrPage(1);
            }
            setIsLoading(false);
          }}
        >
          Finished
        </button>
      </>,
      <>
        <div className={styles.step}>
          1. Now you'll want to navigate to this link to install the Github App:{" "}
          <a href="https://github.com/apps/notion-ctm">
            https://github.com/apps/notion-ctm
          </a>
        </div>
        <div className={styles.step}>
          Install it to as many repositories as you'd like :)
        </div>
        <button
          className={styles.finishedBtn}
          onClick={async () => {
            setCurrPage(2);
          }}
        >
          Finished
        </button>
      </>,
      <>
        <div className={styles.step}>
          1. Navigate back to your newly installed Notion template
        </div>
        <div className={styles.step}>
          2. In order to use your task manager properly, create a new task in
          to-do, and give it a unique ID that no other task has.
        </div>
        <img
          style={{ width: "25%" }}
          src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_4.PNG?alt=media&token=ee16893c-e2be-482b-a5f8-19a940802a3a"
        />
        <div className={styles.step}>
          3. Then when making commits, or opening PRs you'll want to make sure
          that the commit message or the title of your PR starts off with "ID:
          ...." and the github app will automatically change the status of the
          tasks, display recent commits, PRs and any changes to the status of a
          PR.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_5.PNG?alt=media&token=5fd1b3cb-bde6-4806-aea4-6bf95b0fae1b" />
            <img
              style={{ width: "100%" }}
              src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_6.PNG?alt=media&token=fad607f5-2443-45ff-8e2f-400093c61f1e"
            />
          </div>
          <div style={{ width: "50%" }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_7.PNG?alt=media&token=3f87868f-865b-4e94-be49-7f5b59a21b6d" />
            <img src="https://firebasestorage.googleapis.com/v0/b/notion-ctm-7ee55.appspot.com/o/Setup_8.PNG?alt=media&token=08745432-1184-48a2-b258-f0af30bbfa20" />
          </div>
        </div>
        <button
          className={styles.finishedBtn}
          onClick={async () => {
            setCurrPage(3);
          }}
        >
          Finished
        </button>
      </>,
      <>
        <div
          style={{ alignSelf: "center", fontSize: "22px", fontWeight: "bold" }}
        >
          All Set!
        </div>
        <div style={{ alignSelf: "center", fontSize: "18px" }}>
          You're ready to go! Now get to cracking down on those tasks!
        </div>
        <div style={{ alignSelf: "center", fontSize: "18px" }}>
          If you're confused or forget how to do commits and PRs, you can always
          come back to{" "}
          <a href="https://notion-ctm.com/setup">
            https://notion-ctm.com/setup
          </a>
        </div>
        <div style={{ alignSelf: "center", fontSize: "18px" }}>
          If you ever need to update your database ids or cancel your
          subscription you can navigate to{" "}
          <a href="https://notion-ctm.com/account">
            https://notion-ctm.com/account
          </a>{" "}
          to take care of that
        </div>
      </>,
    ],
    [databaseIds]
  );
  const asyncUseEffect = async () => {
    setCanProceed(
      (await hasSubscription(
        auth.currentUser.providerData[0].uid
      )) as unknown as boolean
    );
    setIsLoading(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/auth/signup");
    } else {
      asyncUseEffect();
    }
  }, []);
  useEffect(() => {
    if (!canProceed) {
      navigate("/subscription");
    }
  }, [canProceed]);

  return (
    <div className={styles.Setup}>
      <div className={styles.guide}>
        {isLoading ? (
          <Fade direction="right" style={{ height: "100%" }}>
            <div className={styles.loadingContainer}>
              <CircularProgress style={{ color: "teal" }} />
            </div>
          </Fade>
        ) : (
          <Fade direction="right" style={{ height: "100%" }}>
            <div className={styles.pageContainer}>{pages[currPage]}</div>
          </Fade>
        )}
      </div>
    </div>
  );
};
export default Setup;
