import React from "react";
import { useNavigate } from "react-router-dom";
import { signInWithGit } from "../../../common/firebase/utils";
import styles from "./signup.module.scss";
const SignUp = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.SignUp}>
      <div className={styles.middleBlob}>
        <div className={styles.title}>Sign Up With Github</div>
        <img
          className={styles.image}
          src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
        />
        <button
          className={styles.button}
          onClick={() => {
            signInWithGit().then(() => {
              navigate("/subscription");
            });
          }}
        >
          Sign Up
        </button>
        <div>
          By signing up, you agree to our Terms of Use, and Privacy Policy.
        </div>
      </div>
    </div>
  );
};
export default SignUp;
