import {
  getAuth,
  GithubAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ACTIONS } from "../redux/actions";

const firebaseConfig = {
  apiKey: "AIzaSyDCSPfS2LDtArTY9hxsqlTXvWM2DTr0BXM",
  authDomain: "notion-ctm-7ee55.firebaseapp.com",
  projectId: "notion-ctm-7ee55",
  storageBucket: "notion-ctm-7ee55.appspot.com",
  messagingSenderId: "295026367371",
  appId: "1:295026367371:web:4855029b8453e0587ced8e",
  measurementId: "G-DNEND74JND",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
const firestore = getFirestore();
const gitProvider = new GithubAuthProvider();
const functions = getFunctions();

export const signInWithGit = async () => {
  await signInWithPopup(auth, gitProvider)
    .then(async (result) => {
      const user = result.user;
      if (
        !(
          await getDoc(doc(firestore, "users", user.providerData[0].uid))
        ).exists()
      ) {
        logEvent(analytics, "sign_up");
        await initializeUserWithData(user.providerData[0].uid, user.uid);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createSetupIntent = async () => {
  return await httpsCallable(functions, "createSetupIntent")();
};

export const submitSubscription = async (
  githubId: string,
  subscriptionType: string,
  paymentMethodId: string,
  fullName: string
) => {
  return await httpsCallable(
    functions,
    "createStripeCustomerWithSubscription"
  )({ githubId, subscriptionType, paymentMethodId, fullName });
};

export const hasSubscription = async (githubId: string) => {
  return await httpsCallable(functions, "hasSubscription")({ githubId });
};

export const cancelSubscription = async (githubId: string) => {
  return await httpsCallable(functions, "cancelSubscription")({ githubId });
};

export const initializeUserWithData = async (
  githubId: string,
  firebaseId: string
) => {
  await setDoc(doc(firestore, "users", githubId), {
    githubId,
    firebaseId,
    stripeId: "",
    notion_databases: {
      task_database_id: "",
      commit_database_id: "",
      pr_database_id: "",
    },
  });
};

export const logOut = async () => {
  return await signOut(auth);
};

export const updateDatabaseIds = async (database_ids: any) => {
  await updateDoc(
    doc(firestore, "users", auth.currentUser.providerData[0].uid),
    {
      notion_databases: database_ids,
    }
  );
};

export const listenToUserData = (dispatch) => {
  if (auth.currentUser?.providerData) {
    return onSnapshot(
      doc(firestore, "users", auth.currentUser.providerData[0].uid),
      (doc) => {
        dispatch({ type: ACTIONS.UPDATE_USER_DATA, payload: doc.data() });
      }
    );
  }
};
export const monitorAuth = (initiateListener: Function) => {
  let unsubFromListener;
  return onAuthStateChanged(auth, (newUser) => {
    if (newUser?.providerData) {
      unsubFromListener = initiateListener();
    } else if (unsubFromListener) {
      unsubFromListener();
    }
  });
};
