import { ACTIONS, UpdateUserAction } from "./actions";

export const rootReducer = (
  state: { userData: any },
  action: UpdateUserAction
) => {
  switch (action.type) {
    case ACTIONS.UPDATE_USER_DATA: {
      return { userData: action.payload };
    }
    case ACTIONS.LOG_OUT: {
      return { userData: null };
    }
    default: {
      return { ...state };
    }
  }
};
